import Swiper from "swiper";

import {
	generateCheckboxes,
	generateForm,
	generateRadio,
	generateRange,
	generateSortable,
	generateTextArea,
	generateValidation,
	getNextSlideIndex,
	slideTypeNotice,
} from "@/js/modules/quizino";

// Tu appelles comme ça la classe dans ton app.js : new Quizino(el, form)
// swiper : ta div qui va te servir de swiper, il faudra y mettre un swiper-container, swiper-pagination si besoin etc..
// form : le formulaire drupal

const imgUrl = (base, uri) =>
	`${base}/sites/aviti-intel-ws/themes/sdt/dist/img/${uri}`;

const bgUrl = base => imgUrl(base, "left-bg@2x.jpg");

export default class Quizino {
	constructor(swiper, form) {
		// Get DOM
		this.swiper = new Swiper(swiper, {
			// effect: "fade",
			pagination: {
				el: ".swiper-pagination",
				type: "progressbar",
			},
			navigation: {
				nextEl: ".m-investigation_next",
				prevEl: ".m-investigation_prev",
			},
			slidesPerView: 1,
			autoHeight: true,
			allowTouchMove: false,
			preventClicks: false,
			preventClicksPropagation: false,
			threshold: 20,
		});
		this.form = form;

		// eslint-disable-next-line no-restricted-globals
		const url = `${location.protocol}//${location.host}${location.pathname}`;

		// Generate Home slide
		this.generateStartSlide(url);

		// Generate DOM
		this.generateQuestionsSlides(url);
	}

	generateStartSlide(url) {
		const slide = document.createElement("div");
		slide.classList.add("swiper-slide", "a-focusTrap");

		slide.innerHTML = `<div class="m-investigation_item -first">
            <div class="m-investigation_left">
                <div class="m-investigation_logos -block">
                    <div class="m-investigation_logo">
						<img
							src="${imgUrl(url, "logo.png")}"
							alt="Aviti, Microsoft et Intel"
							width="1112"
							height="101"
						/>
					</div>
                </div>
                <h1 class="a-heading -h1 -white -verticalCenter">
                	Avez-vous <span class="a-heading_blue">anticipé votre migration</span> vers Windows Server 2022&nbsp;?
                </h1>
                <div class="m-investigation_background -mobile">
                    <img src="${bgUrl(url)}" alt="" width="2592" height="2916"/>
                </div>
            </div>
            <div class="m-investigation_right">
                <div class="m-investigation_answers">
                    <div class="m-investigation_intro">
                        <div class="a-text">
                        	<strong>
                        		10 questions pour anticiper la fin de Windows Server 2012 et réussir votre transformation numérique
                            </strong><br/>
                            <br/>
                        	Dans quelques mois, Windows Server 2012 ne sera plus pris en charge.<br/>
                        	Comment réussir cette migration&nbsp;?<br/>
                        	En connaissez&zwj;-&zwj;vous tous les avantages&nbsp;?
                        </div>
                    </div>
                    <button type="button" class="m-investigation_next a-btn">
                    	Faites le test&nbsp;!
                    </button>
                </div>
            </div>
            <div class="m-investigation_background -desktop">
                <img src="${bgUrl(url)}" alt=""/>
            </div>
        </div>`;

		this.swiper.wrapperEl.append(slide);

		//Listener
		slide
			.querySelector(".m-investigation_next")
			.addEventListener("click", () => {
				this.swiper.slideNext();
				window.scrollTo(0, 0);
			});
	}

	generateQuestionsSlides(url) {
		const fieldsets = this.form.querySelectorAll("fieldset");

		const nbrQuestion = fieldsets.length;

		fieldsets.forEach((fieldset, counter) => {
			const labels = fieldset.querySelectorAll("label");
			const inputs = fieldset.querySelectorAll(
				"input:not([type='hidden'])"
			);
			const optionsRange = fieldset.querySelectorAll(
				".description > ul > li"
			);
			const legendDescription = fieldset.querySelector(
				".legend-description"
			)
				? fieldset.querySelector(".legend-description").innerText
				: "";
			const legend = fieldset.querySelector("legend");
			const textarea = fieldset.querySelector("textarea");

			this.generateSlide(
				fieldset,
				counter,
				fieldset.dataset.type,
				labels,
				inputs,
				optionsRange,
				counter,
				nbrQuestion,
				legend,
				textarea,
				url,
				legendDescription
			);
		});

		// Generate end slide after all questions slides
		this.generateEndSlide(url);
	}

	/**
	 * @param {HTMLFieldSetElement} fieldset
	 * @param {number} index
	 * @param {QuizinoSlideType} type
	 * @param {NodeListOf<HTMLLabelElement>} labels
	 * @param {NodeListOf<HTMLInputElement>} inputs
	 * @param {NodeListOf<HTMLLIElement>} optionsRange
	 * @param {number} counter
	 * @param {number} nbrQuestion
	 * @param {HTMLLegendElement} legend
	 * @param {HTMLTextAreaElement} textarea
	 * @param {string} url
	 * @param {string} legendDescription
	 */
	generateSlide(
		fieldset,
		index,
		type,
		labels,
		inputs,
		optionsRange,
		counter,
		nbrQuestion,
		legend,
		textarea,
		url,
		legendDescription
	) {
		const slideName = fieldset.dataset.drupalSelector.replace(/^edit-/, "");

		const slide = document.createElement("div");
		slide.classList.add("swiper-slide", "a-focusTrap");
		slide.setAttribute("data-name", slideName);

		const notice = slideTypeNotice(type);

		const btnLabel = fieldset.dataset.buttonLabel || "Valider";

		const slideContent = `<div class="m-investigation_item">
                <div class="m-investigation_left">
                    <div class="m-investigation_prev a-text -white">Question précédente</div>
                    <div class="m-investigation_compteur">
                    	Question ${counter + 1}&zwj;/&zwj;${nbrQuestion}
                    </div>
                    <h2 class="a-heading -h2 -white">${this.breakingspace(
						legend.innerText
					)}</h2>
                    <span class="a-heading -subtitle -white">${this.breakingspace(
						legendDescription
					)}</span>
                    <div class="m-investigation_logos -inline">
                        <div class="m-investigation_logo">
							<img
								src="${imgUrl(url, "logo.png")}" 
								alt="Aviti, Microsoft et Intel"
								width="1112"
								height="101"
							/>
						</div>
                    </div>
                    <div class="m-investigation_background -mobile">
                        <img
                        	src="${bgUrl(url)}"
                        	alt=""
                        	width="2592"
                        	height="2916"
                        />
                    </div>
                </div>
                <div class="m-investigation_right">
                    <div class="m-investigation_answers">
                        <div class="m-investigation_info">
                            <svg class="a-svg -stroke -primary" viewBox="0 0 71 65">
                                <use xlink:href="#computer" />
                                <text x="50%" y="50%" text-anchor="middle">
									${counter + 1}
								</text>
                            </svg>
                            <div class="a-text">
                            	<strong>
									${notice}
								</strong>
                            </div>
                        </div>
                        <div class="m-investigation_form -${type}">
                            <fieldset class="m-form -${type}" data-scrollable>
                            	<div class="a-shadow -top" aria-hidden="true"></div>
                            	<div class="a-shadow -bottom" aria-hidden="true"></div>
							</fieldset>
                        </div>

                        <div class="m-investigation_text a-text"></div>

                        <button type="button" class="m-investigation_next a-btn">
                        	${btnLabel}
                        </button>
                    </div>
                </div>
                <div class="m-investigation_background -desktop">
                    <img src="${bgUrl(url)}" alt=""/>
                </div>
            </div>`;

		slide.innerHTML = slideContent;
		this.swiper.wrapperEl.append(slide);

		const slideForm = slide.querySelector(".m-form");

		// eslint-disable-next-line default-case
		switch (type) {
			case "validation":
				generateValidation(slide);
				break;

			case "form":
				generateForm(slide, labels, inputs);
				break;

			case "textarea":
				generateTextArea(slide, textarea);
				break;

			case "radio":
				generateRadio(slide, labels, inputs, fieldset);
				break;

			case "checkboxes":
				generateCheckboxes(slide, labels, inputs, fieldset);
				break;

			case "range":
				generateRange(slide, optionsRange, inputs);
				break;

			case "sortable":
				generateSortable(slide, labels, inputs);
				break;
		}

		//Listeners

		const next = slide.querySelector(".m-investigation_next");
		const prev = slide.querySelector(".m-investigation_prev");

		if (next) {
			next.addEventListener("click", e => {
				if (e.defaultPrevented) {
					return;
				}

				if (slideForm.querySelector(":invalid") !== null) {
					e.preventDefault();
					return;
				}

				if (counter + 1 === nbrQuestion) {
					this.form.submit();
				}

				const nextSlideIndex = getNextSlideIndex(
					slide,
					fieldset,
					inputs,
					this.swiper
				);

				if (typeof nextSlideIndex === "number") {
					this.swiper.slideTo(nextSlideIndex);
				} else {
					this.swiper.slideNext();
				}

				this.prevIndex = index + 1; // skip first/intro slide

				window.scrollTo(0, 0);
			});
		}

		if (prev) {
			prev.addEventListener("click", () => {
				if (typeof this.prevIndex !== "undefined") {
					// console.log("slidePrev", this.prevIndex);
					this.swiper.slideTo(this.prevIndex);
					this.prevIndex = undefined;
				} else {
					this.swiper.slidePrev();
				}

				window.scrollTo(0, 0);
			});
		}
	}

	generateEndSlide(url) {
		this.swiper.appendSlide(`<div class="swiper-slide a-focusTrap">
            <div class="m-investigation_item -last">
                <div class="m-investigation_left">
                    <h2 class="a-heading -h1Alt -white -verticalCenter">
                    Merci pour votre participation&nbsp;!
                    </h2>
                    <div class="m-investigation_logos -inline">
                        <div class="m-investigation_pavageau">
							<img
								src="${imgUrl(url, "logo.png")}" 
								alt="Aviti, Microsoft et Intel"
								width="1112"
								height="101"
							/>
						</div>
                    </div>
                    <div class="m-investigation_background -mobile">
                        <img src="${bgUrl(url)}" alt=""/>
                    </div>
                </div>
                <div class="m-investigation_right">
                    <div class="m-investigation_answers">
                        <div class="m-investigation_check">
                            <svg class="a-svg -stroke -primary" viewBox="0 0 191 191">
                                <use xlink:href="#big-checkmark" />
                            </svg>
                        </div>
                        <div class="a-text -centered -primary">
                            <p>
								Les résultats de cette étude vont nous permettre de mieux comprendre
								vos besoins et d'élaborer des objets publicitaires
								davantage en cohérence avec vos aspirations.
                            </p>
                        </div>

                    </div>
                </div>
                <div class="m-investigation_background -desktop">
                    <img src="${bgUrl(url)}" alt=""/>
                </div>
            </div>
        </div>`);
	}

	// eslint-disable-next-line class-methods-use-this
	breakingspace(text) {
		return text
			.replace(" ?", "&nbsp;?")
			.replace(" !", "&nbsp;!")
			.replace(" :", "&nbsp;:");
	}
}
