import { debounce } from "@/js/modules/delays";

/**
 * @returns {GlobalEventGroup}
 */
const makeEventGroup = () => {
	const eventGroup = {
		listeners: [],
		eventListener(e) {
			this.listeners.forEach(listener => {
				listener(e);
			});
		},
	};

	eventGroup.eventListener = debounce(eventGroup.eventListener, 16, {
		immediate: true,
		thisArg: eventGroup,
	});

	return eventGroup;
};

export const GlobalEventBus = {
	/**
	 * @property {Record<GlobalEventType, GlobalEventGroup>} __
	 * @private
	 * @readonly
	 */
	__: {
		resize: makeEventGroup(),
		orientationchange: makeEventGroup(),
		scroll: makeEventGroup(),
	},
	/**
	 * Add a listener to the global events bus
	 * @param {GlobalEventType} eventType
	 * @param {Function} listener
	 */
	on(eventType, listener) {
		const eventGroup = this.__[eventType];
		eventGroup.listeners.push(listener);
		return GlobalEventBus;
	},

	/**
	 * Remove a listener from the global events bus
	 * @param {GlobalEventType} eventType
	 * @param {Function} listener
	 */
	off(eventType, listener) {
		const eventGroup = this.__[eventType];
		eventGroup.listeners = eventGroup.listeners.filter(l => l !== listener);
		return GlobalEventBus;
	},
};

export const bootGlobalEventBus = () => {
	Object.entries(GlobalEventBus.__).forEach(([eventName, eventGroup]) => {
		window.addEventListener(eventName, eventGroup.eventListener, {
			passive: true,
		});
	});
};
