import "@/scss/main.scss";
import "@/js/gen-svg-spritesheet";
import "lazysizes";
import "lazysizes/plugins/respimg/ls.respimg";

import Notify from "@/js/libs/Notify";
import ScrollDirection from "@/js/libs/ScrollDirection";
import { Utm } from "@/js/libs/Utm";
import { bootstrapModules } from "@/js/modules";
import { bootstrapBreakpoints } from "@/js/modules/breakpoints";
import { makeBrowserDetector } from "@/js/modules/browserDetector";
import { bootGlobalEventBus } from "@/js/modules/globalEvents";
import HomeRenderer from "@/js/renderers/HomeRenderer";
import PageRenderer from "@/js/renderers/PageRenderer";

document.addEventListener("DOMContentLoaded", function () {
	// Breakpoints
	bootstrapBreakpoints();
	bootGlobalEventBus();

	// Renderer
	let renderer = false;
	switch (document.body.dataset.type) {
		case "home":
			renderer = new HomeRenderer();
			break;
		default:
			renderer = new PageRenderer();
			break;
	}
	renderer.onEnter();

	const utm = new Utm();
	utm.makeCookies();

	// Scroll direction
	const scrollDirection = new ScrollDirection();
	scrollDirection.init();

	// ContentInfo
	const notify = new Notify();
	const contentinfos = document.querySelectorAll('div[role="contentinfo"]');
	contentinfos.forEach(contentinfo => {
		notify.add(
			contentinfo,
			!contentinfo.classList.contains("contentinfo--error")
				? "good"
				: "bad"
		);
	});

	bootstrapModules();
});

// register CSS classes on <html> to help with browser detection in CSS
makeBrowserDetector(window).bootstrapClasses();

// SWPL LOG
console.log(
	"%c swimmingpool ",
	`background: #1467ff;
	color: white;
	font-size: 17px;
	font-weight: bold;
	line-height: 36px; text-align: center`,
	" www.swpl.fr"
);
