export const Breakpoints = {
	__default: 0,
	small: 568,
	medium: 768,
	large: 1024,
	xlarge: 1280,
	xxlarge: 1440,
	xxxlarge: 1680,
	xxxxlarge: 1920,
};

export const belowBreakpoint = bp => window.innerWidth < bp;

export const aboveBreakpoint = bp => !belowBreakpoint(bp);

export const currentBreakpoint = () => {
	/*
		 Current breakpoint is the last for which the
		 window's inner width is above
	 */
	return Object.values(Breakpoints).reverse().find(aboveBreakpoint);
};

export const currentBreakpointIs = bp => {
	const current = currentBreakpoint();
	return bp === current;
};

/**
 * Breakpoint events helper
 */
export const BreakpointEvents = {
	/**
	 * @private
	 */
	__: {
		/**
		 * @type {number}
		 */
		lastBreakpoint: currentBreakpoint(),
		listeners: {
			/**
			 * @type {BreakpointEventListener[]}
			 */
			change: [],

			/**
			 * @type {BreakpointEventListener[]}
			 */
			bigger: [],

			/**
			 * @type {BreakpointEventListener[]}
			 */
			smaller: [],
		},
	},
	/**
	 * Attach event listeners
	 */
	on: {
		/**
		 * Get notified on breakpoint change
		 * @param {BreakpointEventListener} listener
		 * @param {Partial<BreakpointEventOptions>} [options]
		 */
		change(listener, { immediate = true } = {}) {
			BreakpointEvents.__.listeners.change.push(listener);

			if (immediate) {
				listener(currentBreakpoint());
			}
		},

		/**
		 * Get notified on breakpoint change (only when it gets bigger)
		 * @param {BreakpointEventListener} listener
		 * @param {Partial<BreakpointEventOptions>} [options]
		 */
		bigger(listener, { immediate = true } = {}) {
			BreakpointEvents.__.listeners.bigger.push(listener);

			if (immediate) {
				listener(currentBreakpoint());
			}
		},

		/**
		 * Get notified on breakpoint change (only when it gets smaller)
		 * @param {BreakpointEventListener} listener
		 * @param {Partial<BreakpointEventOptions>} [options]
		 */
		smaller(listener, { immediate = true } = {}) {
			BreakpointEvents.__.listeners.smaller.push(listener);

			if (immediate) {
				listener(currentBreakpoint());
			}
		},

		/**
		 * Get notified on breakpoint change (only when it gets bigger than the provided breakpoint)
		 * @description It's only called once until the current breakpoint is below the provided one
		 * @param {number} breakpoint
		 * @param {BreakpointEventListener} listener
		 * @param {Partial<BreakpointEventOptions>} [options]
		 */
		biggerThan(breakpoint, listener, { immediate = true } = {}) {
			this.bigger(
				prev => {
					const wasBelow = prev < breakpoint;
					const isAbove = aboveBreakpoint(breakpoint);

					if (wasBelow && isAbove) {
						listener(prev);
					}
				},
				{ immediate: false }
			);

			if (immediate && aboveBreakpoint(breakpoint)) {
				listener(currentBreakpoint());
			}
		},

		/**
		 * Get notified on breakpoint change (only when it gets smaller than the provided breakpoint)
		 * @description It's only called once until the current breakpoint is above the provided one
		 * @param {number} breakpoint
		 * @param {BreakpointEventListener} listener
		 * @param {Partial<BreakpointEventOptions>} [options]
		 */
		smallerThan(breakpoint, listener, { immediate = true } = {}) {
			this.smaller(
				prev => {
					const wasAbove = prev > breakpoint;
					const isBelow = belowBreakpoint(breakpoint);

					if (wasAbove && isBelow) {
						listener(prev);
					}
				},
				{ immediate: false }
			);

			if (immediate && belowBreakpoint(breakpoint)) {
				listener(currentBreakpoint());
			}
		},
	},
};

/**
 * Handle breakpoint events on resize
 */
const onResize = () => {
	const bp = currentBreakpoint();
	const last = BreakpointEvents.__.lastBreakpoint;
	BreakpointEvents.__.lastBreakpoint = bp;

	if (bp === last) {
		// Only trigger on breakpoint change
		return;
	}

	const callListenersFor = type => {
		BreakpointEvents.__.listeners[type].forEach(f => f(last));
	};

	// On breakpoint change
	callListenersFor("change");

	if (bp > last) {
		// When the viewport got bigger
		callListenersFor("bigger");
	} else {
		// When the viewport got smaller
		callListenersFor("smaller");
	}
};

export const bootstrapBreakpoints = () => {
	window.addEventListener("resize", onResize);
	window.addEventListener("orientationchange", onResize);
};
