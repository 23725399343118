import Quizino from "@/js/libs/Quizino";

export default class HomeRenderer {
	onEnter() {
		const investigation = document.querySelector(".m-investigation");
		const investigationForm = document.querySelector(
			".webform-submission-form"
		);

		this.quizino = new Quizino(investigation, investigationForm);
		window.quizino = this.quizino;
	}
}
