const cookieDuration = 3600;
const searchSubstr = 1;

/**
 * Compatibility class for browsers w/o {@link URLSearchParams}
 * @extends SearchParams
 */
class ParamsCompat {
	constructor(location = window.location) {
		this.location = location;
		this.params = location.search // "?param=stuff&random=pelo"
			.substr(searchSubstr) // "param=stuff&random=pelo"
			.split("&") // ["param=stuff", "random=pelo"]
			.map(eq => eq.split("=")); // [["param", "stuff"], ["random", "pelo"]]
		this.params = Object.fromEntries(this.params); // {param: "stuff", random: "pelo"}
	}

	has(key) {
		return this.get(key) !== null;
	}

	get(key) {
		return key in this.params ? decodeURIComponent(this.params[key]) : null;
	}
}

export const compatParams = location => new ParamsCompat(location);
const defaultParams = location => {
	return new URLSearchParams(location.search.substr(searchSubstr));
};

/**
 * A class that handles actions related to UTM
 */
export class Utm {
	/**
	 * A
	 * @param {object} [settings] - The settings for the Utm
	 * @param {Location} [settings.location] - The current location
	 * @param {string} [settings.prefix] - The UTM prefix
	 * @param {SearchParamsFactory} [settings.paramsFactory] - The search params
	 */
	constructor({
		location = window.location,
		prefix = "utm_",
		paramsFactory = defaultParams,
	} = {}) {
		this.location = location;
		this.params = paramsFactory(this.location);
		this.prefix = prefix;
	}

	/**
	 * @private
	 */
	_key(key) {
		return `${this.prefix}${key}`;
	}

	/**
	 * Determine whether or not there is data for the given key
	 * @param {string} key - The UTM key
	 * @returns {boolean}
	 */
	has(key) {
		return this.params.has(this._key(key));
	}

	/**
	 * Retrieve the data for the given key
	 * @param {string} key - The UTM key
	 * @returns {string|null}
	 */
	get(key) {
		return this.params.get(this._key(key));
	}

	/**
	 * Create the associated cookie for the given UTM key
	 * @param {string} key - The UTM key
	 * @param {number} [expires = 3600] - Duration before the cookie expires
	 * @param {string} [path = "/"] - The path for the cookie
	 * @returns {Utm}
	 */
	makeCookieFor(key, expires = cookieDuration, path = "/") {
		if (this.has(key)) {
			const value = this.get(key);
			const name = this._key(key);
			document.cookie = `${name}=${value}; expires=${expires}; path=${path}`;
		}

		return this;
	}

	/**
	 * Create the associated cookies for the given UTM keys
	 * @param {string[]} [keys] - The UTM keys
	 * @param {number} [expires = 3600] - Duration before the cookie expires
	 * @param {string} [path = "/"] - The path for the cookie
	 * @returns {Utm}
	 */
	makeCookies(
		keys = ["source", "medium", "campaign"],
		expires = cookieDuration,
		path = "/"
	) {
		keys.forEach(key => this.makeCookieFor(key, expires, path));

		return this;
	}
}
