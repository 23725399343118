import rangeSlider from "rangeslider-pure";
import Sortable from "sortablejs";

import { GlobalEventBus } from "@/js/modules/globalEvents";

/**
 * @type {Record<QuizinoSlideType, string>}
 */
const notices = {
	validation: "Voulez-vous valider vos réponses ?",
	form: "Remplissez les champs ci-dessous",
	radio: "Choisissez votre réponse parmi les choix",
	checkboxes:
		"Choisissez vos réponses parmi les choix (Plusieurs réponses possibles)",
	range: "Faites varier le curseur de haut en bas",
	sortable:
		"Classez du plus important au moins important en faisant un glisser-déposer",
	textarea:
		"Remplissez le champ (question optionnelle, validez sans renseigner de réponse pour passer)",
};

/**
 * @param {QuizinoSlideType} type
 * @returns {string}
 */
export const slideTypeNotice = type => notices[type] ?? "";

/**
 * @param {HTMLElement} slide
 * @param {HTMLFieldSetElement} fieldset
 * @param {HTMLElement[]} items - The fieldset's inputs
 */
const addQuizzAnswersMode = (slide, fieldset, items) => {
	const validIndexes = new Set(JSON.parse(fieldset.dataset.valid));

	const button = slide.querySelector(".m-investigation_next");

	button.addEventListener("click", e => {
		if (fieldset.dataset.validated === "true") {
			return;
		}

		e.preventDefault();
		e.stopPropagation();

		const inputs = items.map(item => item.querySelector("input"));

		// const checkedOneValid = inputs.some(
		// 	(input, index) => validIndexes.has(index) && input.checked
		// );

		items.forEach((item, index) => {
			const isValid = validIndexes.has(index);

			const input = inputs[index];

			if (isValid) {
				// if (input.checked || !checkedOneValid) {
				item.classList.add("-valid");
				// } else {
				// 	item.classList.add("-alsoValid");
				// }
			} else if (input.checked) {
				item.classList.add("-invalid");
			}

			input.setAttribute("disabled", "disabled");
		});

		fieldset.setAttribute("data-validated", "true");
	});
};

/**
 * @param {HTMLInputElement} input - The input in the "true" form (the one that needs to be synced)
 * @param {HTMLInputElement} newInput - The "façade" input
 * @param {HTMLElement} errorWrapper
 * @returns {boolean}
 */
const validateMap = (input, newInput, errorWrapper) => {
	const isValid = newInput.checkValidity();

	if (!isValid) {
		const errMsg = newInput.validity.valueMissing
			? input.dataset.webformRequiredError
			: newInput.validationMessage;

		errorWrapper.textContent = errMsg ?? input.dataset.webformRequiredError;
	}

	return isValid;
};

/**
 * @param {HTMLElement} label
 * @param {string} textInputName
 */
const addInlineTextField = (label, textInputName, tweak = () => {}) => {
	const textInput = document.querySelector(
		`input[type="text"][name="${textInputName}"]`
	);

	if (!textInput) {
		return;
	}

	/**
	 * @type {HTMLInputElement}
	 */
	const newTextInput = textInput.cloneNode(true);
	newTextInput.setAttribute("id", `${textInput.id}-slide`);

	label.appendChild(newTextInput);

	newTextInput.addEventListener(
		"keyup",
		() => {
			textInput.value = newTextInput.value;
		},
		{
			passive: true,
		}
	);

	const span = label.querySelector("span");
	span.classList.remove("-alone");

	const onResize = () => {
		const spanWidth = span.offsetWidth;
		// 20px = margin left + margin right
		newTextInput.style.width = `calc(100% - ${spanWidth}px - 20px)`;
	};

	onResize();
	GlobalEventBus.on("resize", onResize);
	GlobalEventBus.on("orientationchange", onResize);

	tweak(newTextInput);
};

/**
 * @param {HTMLFieldSetElement} slide
 * @param {HTMLFieldSetElement} fieldset
 * @param {NodeListOf<HTMLInputElement>} inputs - The fieldset's inputs
 * @param {Swiper} swiper
 * @returns {number|null}
 */
export const getNextSlideIndex = (slide, fieldset, inputs, swiper) => {
	const checkedInput = slide.querySelector("input:checked");
	const checkedValue = checkedInput?.value;
	const originalInput = [...inputs].find(
		input => input.value === checkedValue
	);
	const originalWrapper = originalInput?.closest?.("[data-next]");

	const nextSlideName = originalWrapper?.dataset?.next?.trim?.();

	if (!checkedInput || !nextSlideName) {
		return null;
	}

	return (
		[...swiper.slides].findIndex(
			slideEl => slideEl.dataset.name === nextSlideName
		) ?? null
	);
};

export const checkedradio = (inputs, slide) => {
	const oneIsChecked = inputs.some(inputForm => inputForm.checked);

	if (oneIsChecked) {
		slide
			.querySelector(".m-investigation_next")
			.classList.remove("disabled", "-disabled");
	} else {
		slide
			.querySelector(".m-investigation_next")
			.classList.add("disabled", "-disabled");
	}
};

/**
 * @param {HTMLElement} slide
 */
export const generateValidation = slide => {
	const makeButton = (textContent, id) => {
		const fullId = `checkpoint-${slide.dataset.name}-${id}`;

		const item = document.createElement("div");
		item.classList.add("m-form_itemCheckbox");

		const input = document.createElement("input");
		input.type = "radio";
		input.name = `checkpoint-${slide.dataset.name}`;
		input.id = fullId;
		item.append(input);

		const label = document.createElement("label");
		label.htmlFor = fullId;
		label.innerHTML = `<span class="-alone">${textContent}</span>`;
		item.append(label);

		return {
			item,
			button: input,
		};
	};

	const ok = makeButton("Oui", "__ok");
	const ko = makeButton("Non", "__ko");
	const inputs = [ok.button, ko.button];

	const form = slide.querySelector(".m-form");
	form.appendChild(ok.item);
	form.appendChild(ko.item);

	const prevBtn = slide.querySelector(".m-investigation_prev");
	const continueBtn = slide.querySelector(".m-investigation_next");

	ok.button.addEventListener("change", e => {
		e.preventDefault();
		checkedradio(inputs, slide);
		continueBtn.click();
	});

	ko.button.addEventListener("change", e => {
		e.preventDefault();
		checkedradio(inputs, slide);
		prevBtn.click();
	});
};

/**
 * @param {HTMLElement} slide
 * @param {NodeListOf<HTMLLabelElement>} labels
 * @param {NodeListOf<HTMLInputElement>} inputs
 */
export const generateForm = (slide, labels, inputs) => {
	const form = slide.querySelector(".m-form");

	/**
	 * @type {([input: HTMLInputElement, newInput: HTMLInputElement, errorWrapper: HTMLElement])[]}
	 */
	const inputMaps = [];

	inputs.forEach((input, i) => {
		const label = labels.item(i);
		const wrapper = label.parentElement;

		/**
		 * @type {HTMLInputElement}
		 */
		const newInput = input.cloneNode(true);
		newInput.id = `${input.id}-slide`;

		/**
		 * @type {HTMLLabelElement}
		 */
		const newLabel = label.cloneNode(true);
		newLabel.setAttribute("for", newInput.id);

		const errorWrapper = document.createElement("div");
		errorWrapper.classList.add("form-item--error-message");

		const field = document.createElement("div");
		wrapper.getAttributeNames().forEach(attrName => {
			const attrValue = wrapper.getAttribute(attrName);
			field.setAttribute(attrName, attrValue);
		});

		field.append(newLabel, newInput, errorWrapper);

		form.appendChild(field);

		newInput.addEventListener(
			"change",
			() => {
				errorWrapper.textContent = "";
			},
			{ passive: true }
		);

		newInput.addEventListener("blur", () => {
			validateMap(input, newInput, errorWrapper);
		});

		inputMaps.push([input, newInput, errorWrapper]);
	});

	const nextBtn = slide.querySelector(".m-investigation_next");

	nextBtn.addEventListener("click", e => {
		const hasErrors = inputMaps.reduce(
			// eslint-disable-next-line no-shadow
			(hasErrors, [input, newInput, errorWrapper]) => {
				const isValid = validateMap(input, newInput, errorWrapper);

				input.checked = newInput.checked;
				input.value = newInput.value;

				return hasErrors || !isValid;
			},
			false
		);

		if (hasErrors) {
			e.preventDefault();
			e.stopPropagation();
		}

		return !hasErrors;
	});
};

/**
 * @param {HTMLElement} slide
 * @param {HTMLTextAreaElement} parentTextarea
 */
export const generateTextArea = (slide, parentTextarea) => {
	const itemText = document.createElement("div");
	itemText.classList.add("m-form_itemEmail");

	const textareaContent = document.createElement("textarea");
	textareaContent.value = parentTextarea.value;
	textareaContent.id = parentTextarea.id;
	textareaContent.name = parentTextarea.name;
	itemText.append(textareaContent);

	slide.querySelector(".m-form").append(itemText);

	//listener
	slide
		.querySelector(".m-investigation_next")
		.addEventListener("click", () => {
			parentTextarea.value = textareaContent.value;
		});
};

/**
 * @param {HTMLElement} slide
 * @param {NodeListOf<HTMLLabelElement>} labels
 * @param {NodeListOf<HTMLInputElement>} inputs
 * @param {HTMLFieldSetElement} fieldset
 */
export const generateRadio = (slide, labels, inputs, fieldset) => {
	const items = [...inputs]
		.map((inputForm, counterRadio) => {
			if (inputForm.type !== "radio") {
				return;
			}

			const item = document.createElement("div");
			item.classList.add("m-form_itemRadio");

			const input = document.createElement("input");
			input.type = "radio";
			input.value = inputForm.value;
			input.id = `${inputForm.id}${counterRadio}`;
			input.name = inputForm.name;
			item.append(input);

			const label = document.createElement("label");
			label.htmlFor = `${inputForm.id}${counterRadio}`;
			label.innerHTML = labels[counterRadio].textContent;
			item.append(label);

			slide.querySelector(".m-form").append(item);

			const descriptionWrapper = slide.querySelector(
				".m-investigation_text"
			);

			const textDescription =
				inputForm.parentElement
					.querySelector(".description")
					?.textContent?.trim?.() ?? "";

			const navNext = slide.querySelector(".m-investigation_next");
			navNext.classList.add("disabled", "-disabled");

			const onChange = () => {
				inputForm.checked = !inputForm.checked;

				if (input.checked) {
					descriptionWrapper.textContent = textDescription;
				}

				navNext.classList.remove("disabled", "-disabled");
			};

			//listener
			item.addEventListener("click", onChange);
			// input.addEventListener("change", onChange);

			// eslint-disable-next-line consistent-return
			return item;
		})
		.filter(x => !!x);

	if (fieldset.dataset.valid) {
		addQuizzAnswersMode(slide, fieldset, items);
	}
};

/**
 * @param {HTMLElement} slide
 * @param {NodeListOf<HTMLLabelElement>} labels
 * @param {NodeListOf<HTMLInputElement>} inputs
 * @param {HTMLFieldSetElement} fieldset
 */
export const generateCheckboxes = (slide, labels, inputs, fieldset) => {
	const items = [...inputs]
		.map((inputForm, counterCheckbox) => {
			if (inputForm.type !== "checkbox") {
				return;
			}

			const item = document.createElement("div");
			item.classList.add("m-form_itemCheckbox");

			const input = document.createElement("input");
			input.type = "checkbox";
			input.value = inputForm.value;
			input.id = `${inputForm.id}${counterCheckbox}`;
			input.name = inputForm.name;
			item.append(input);

			const label = document.createElement("label");
			label.htmlFor = `${inputForm.id}${counterCheckbox}`;
			label.innerHTML = `<span class="-alone">${labels[counterCheckbox].textContent}</span>`;
			item.append(label);

			const description =
				inputForm.parentElement.querySelector(".description");

			const onChange = () => {
				inputForm.checked = !inputForm.checked;
				input.checked = inputForm.checked;

				checkedradio(Array.from(inputs), slide);
			};

			addInlineTextField(
				label,
				description?.textContent?.trim?.() ?? "deadcafe"
			);

			/*
		,
			inlineField => {
				const checkRelatedInput = () => {
					inputForm.checked = true;
					input.checked = true;

					slide
						.querySelector(".m-investigation_next")
						.classList.remove("disabled", "-disabled");
				};

				// inlineField.addEventListener("focus", checkRelatedInput);
	}
		 */

			slide.querySelector(".m-form").append(item);
			slide
				.querySelector(".m-investigation_next")
				.classList.add("disabled", "-disabled");

			item.addEventListener("click", onChange);
			// input.addEventListener("change", onChange);

			// eslint-disable-next-line consistent-return
			return item;
		})
		.filter(x => !!x);

	if (fieldset.dataset.valid) {
		addQuizzAnswersMode(slide, fieldset, items);
	}
};

/**
 * @param {HTMLElement} slide
 * @param {NodeListOf<HTMLOptionElement>} optionsRange
 * @param {NodeListOf<HTMLInputElement>} inputs
 */
export const generateRange = (slide, optionsRange, inputs) => {
	const maxOption = optionsRange.length - 1;

	const item = document.createElement("div");
	item.classList.add("m-form_itemRange");

	const input = document.createElement("input");
	input.type = "range";
	input.step = "1";
	input.min = "0";
	input.max = `${maxOption}`;
	item.append(input);

	const options = document.createElement("div");
	options.classList.add("m-form_options");
	const ul = document.createElement("ul");
	options.append(ul);

	let counterRange = optionsRange.length - 1;

	optionsRange.forEach(optionRange => {
		const li = document.createElement("li");
		li.classList.add("m-form_option", "a-text");
		li.innerHTML = optionRange.innerHTML;
		li.dataset.range = counterRange;
		ul.append(li);

		counterRange -= 1;
	});

	const form = slide.querySelector(".m-form");
	const slideNext = slide.querySelector(".m-investigation_next");

	form.append(item);
	form.append(options);
	slideNext.classList.remove("disabled", "-disabled");

	const ranges = document.querySelectorAll('input[type="range"]');
	const optionsForm = document.querySelectorAll(".m-form_option");

	//Default value
	inputs[0].value = optionsForm[0].innerHTML;

	if (ranges) {
		ranges.forEach(range => {
			rangeSlider.create(range, {
				vertical: true,
				onSlideEnd(position /*, value*/) {
					optionsForm.forEach(optionForm => {
						// eslint-disable-next-line eqeqeq
						if (optionForm.dataset.range == position) {
							inputs[0].value = optionForm.innerHTML;
						}
					});
				},
			});
		});
	}
};

/**
 * @param {HTMLElement} slide
 * @param {NodeListOf<HTMLLabelElement>} labels
 * @param {NodeListOf<HTMLInputElement>} inputs
 */
export const generateSortable = (slide, labels, inputs) => {
	labels.forEach((labelForm, counterSortable) => {
		const formInput = inputs.item(counterSortable);

		if (formInput.type !== "number") {
			return;
		}

		const itemId = `${formInput.id}--${counterSortable}`;

		const item = document.createElement("div");
		item.classList.add("m-form_itemSortable");
		item.dataset.id = `${counterSortable}`;
		item.setAttribute("id", itemId);
		const drag = document.createElement("i");
		drag.classList.add("m-form_handle");
		item.append(drag);

		const label = document.createElement("label");
		const internalId = `${formInput.id}${counterSortable}`; // not the same as itemId
		label.setAttribute("for", internalId);
		label.setAttribute("data-draggable-target", itemId);
		label.innerHTML = `<span class="-alone">${labelForm.textContent}</span>`;
		item.append(label);

		const textInputName = formInput.dataset.textInput;

		addInlineTextField(label, textInputName);

		slide.querySelector(".m-form").append(item);
	});

	slide
		.querySelector(".m-investigation_next")
		.classList.remove("disabled", "-disabled");

	const classement = slide.querySelector(".m-form.-sortable");
	const sortable = Sortable.create(classement, {
		animation: 150,
		/**
		 * @param {Event} evt
		 */
		onEnd() {
			const resultSortable = sortable.toArray();
			inputs.forEach((inputForm, counterInput) => {
				if (inputForm.type !== "number") {
					return;
				}

				inputForm.value = resultSortable[counterInput];
			});
		},
	});
};
