import { GlobalEventBus } from "@/js/modules/globalEvents";

export default class ScrollDirection {
	constructor(delta = 0) {
		this.delta = delta;
		this.currentScrollTop = 0;
		this.prevScrollTop = 0;
		this.currentDir = false;

		this._scrolledClass = "-scrolled";
		this._longScrolledClass = "-longScrolled";

		// Binding
		this.onScroll = this.onScroll.bind(this);
		this.init = this.init.bind(this);
		this.destroy = this.destroy.bind(this);
	}

	/**
	 * Set down or up data on body
	 */
	onScroll() {
		this.currentScrollTop =
			window.pageYOffset ?? document.documentElement.scrollTop;
		const scrolled =
			this.currentScrollTop > this.prevScrollTop + this.delta;
		if (!this.currentDir && scrolled) {
			// On down scroll
			this.prevScrollTop = this.currentScrollTop;
			document.body.setAttribute("data-scroll", "down");
			this.currentDir = true;
		} else if (
			this.currentDir &&
			this.currentScrollTop < this.prevScrollTop
		) {
			// On up scroll
			this.prevScrollTop = this.currentScrollTop;
			document.body.setAttribute("data-scroll", "up");
			this.currentDir = false;
		}
		this.prevScrollTop = this.currentScrollTop;

		this.handleScrollStatus();
	}

	handleScrollStatus() {
		const longScroll = window.innerHeight / 2;
		const scrollPos = window.scrollY;

		if (scrollPos > this.delta) {
			document.body.classList.add(this._scrolledClass);
		} else {
			document.body.classList.remove(this._scrolledClass);
		}

		if (scrollPos > longScroll) {
			document.body.classList.add(this._longScrolledClass);
		} else {
			document.body.classList.remove(this._longScrolledClass);
		}
	}

	/**
	 * Set initial value and add event listener on window scroll
	 */
	init() {
		// Set initial data-scroll
		document.body.setAttribute("data-scroll", "up");

		// Add scroll listener
		GlobalEventBus.on("scroll", this.onScroll);

		this.onScroll();
	}

	/**
	 * Remove event listener on window scroll
	 */
	destroy() {
		// Remove data-scroll
		document.body.removeAttribute("data-scroll");

		// Remove scroll listener
		GlobalEventBus.off("scroll", this.onScroll);
	}
}
