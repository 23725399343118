/**
 * Wait the given amount of milliseconds
 * @param {number} delay - The amount of milliseconds to wait for
 * @returns {Promise<number>}
 */
export const waitMs = delay =>
	new Promise(resolve => setTimeout(resolve, delay));

/**
 * Create a debounced version of the given function
 * @template FuncThisArg, Args, Ret, ThisArg
 * @param {GenericFunction<Args, Ret, FuncThisArg>} func - The function to debounce
 * @param {number} delay - The delay between invocations
 * @param {DebounceOptions<ThisArg>} [options]
 * @returns {GenericFunction<Args, Ret, ThisArg|FuncThisArg>}
 *
 * @see http://www.kevinsubileau.fr/informatique/boite-a-code/php-html-css/javascript-debounce-throttle-reduire-appels-fonction.html
 */
export const debounce = (
	func,
	delay,
	{ immediate = true, thisArg = undefined } = {}
) => {
	/**
	 * @var {Ret}
	 */
	let result;

	/**
	 * @var {?number}
	 */
	let timeout = null;

	/**
	 * @param {Args[]} args
	 * @returns {Ret}
	 */
	return function (...args) {
		/**
		 * @var {ThisArg|FuncThisArg}
		 */
		const ctx = thisArg ?? this;

		const invoke = function () {
			timeout = null;
			if (!immediate) {
				result = func.apply(ctx, args);
			}
		};

		const shouldCallNow = immediate && !timeout;

		if (timeout !== null) {
			clearTimeout(timeout);
		}

		if (shouldCallNow) {
			result = func.apply(ctx, args);
		} else {
			timeout = setTimeout(invoke, delay);
		}

		return result;
	};
};

/**
 * @template FuncThisArg, Args, Ret, ThisArg
 * @param {GenericFunction<Args, Ret, FuncThisArg>} func - The function to throttle
 * @param {number} delay - The delay between invocations
 * @param {ThrottleOptions<ThisArg>} [options]
 * @returns {GenericFunction<Args, Ret, ThisArg|FuncThisArg>}
 *
 * @see http://www.kevinsubileau.fr/informatique/boite-a-code/php-html-css/javascript-debounce-throttle-reduire-appels-fonction.html
 */
export const throttle = (
	func,
	delay,
	{ leading = false, trailing = false, thisArg = undefined } = {}
) => {
	/**
	 * @var {Args[]}
	 */
	let args = [];

	/**
	 * @var {FuncThisArg|ThisArg}
	 */
	let ctx;

	/**
	 * @var {Ret}
	 */
	let result;

	/**
	 * @type {?number}
	 */
	let timeout = null;

	/**
	 * @type {Date|number}
	 */
	let previous = 0;

	const invoke = () => {
		previous = new Date();
		timeout = null;
		result = func.apply(ctx, args);
	};

	/**
	 * @param {Args[]} args
	 * @returns {Ret}
	 */
	return function (...args_) {
		const now = new Date();

		if (!previous && !leading) {
			previous = now;
		}

		const remaining = delay - (now - previous);
		ctx = thisArg || this;
		args = args_;

		if (remaining <= 0) {
			if (timeout !== null) {
				clearTimeout(timeout);
				timeout = null;
			}

			previous = now;
			result = func.apply(ctx, args);
		} else if (!timeout && trailing) {
			timeout = setTimeout(invoke, remaining);
		}

		return result;
	};
};
